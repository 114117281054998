import React from 'react';
import appStore from '../../../assets/app_store.svg';
import ScrollTop from "../../core/ScrollTop";
import Footer from "../../core/Footer";
import StatsSection from "./sections/StatsSection";
import Background from "../../core/Background";

function Landing() {
    return (
        <>
            {/* Start banner section */}
            <section
                className="banner-section relative section-gap-full"
                id="banner-section"
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 banner-left">
                            <div style={{paddingLeft: 16}}>
                                <h1>Judo Mobile</h1>
                                <h2>
                                    Ultimate solution to follow
                                    <br/>
                                    judo tournaments.
                                </h2>
                            </div>
                            <br/>
                            <a href="https://play.google.com/store/apps/details?id=pl.daniel.judo&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img
                                    alt="Get it on Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                    width="280px"
                                />
                            </a>
                            <a href="https://apps.apple.com/app/id1481008402">
                                <img
                                    className="btn"
                                    alt="Download on the App Store"
                                    src={appStore}
                                    width="250px"
                                />
                            </a>
                        </div>
                        <div className="col-md-6 banner-right text-center">
                            <div className="phone-anim">
                                <div className="phone-size">
                                    <iframe
                                        className="phone-frame"
                                        src="https://app.judomobile.app"
                                        title="Judo Mobile App"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End banner section */}
            <StatsSection/>
            {/* Start organizer get started section */}
            <section
                className="get-started-section section-gap-full"
                id="download-section"
            >
                <div className="container">
                    <div className="row get-started-wrap justify-items-between align-items-center">
                        <div className="col-lg-6 titles">
                            <h1>You are an organizer?</h1>
                            <h4>
                                Checkout our comprehensive documentation
                                <br/>
                                and improve your tournament experience.
                            </h4>
                        </div>
                        <div className="col-lg-6 buttons">
                            <a
                                href="https://docs.judomobile.app/docs/console/overview"
                                className="primary-btn"
                            >
                                <span>Get started as organizer</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            {/* End organizer get started section */}

            <Footer/>
            <Background/>
            <ScrollTop/>
        </>
    );
}

export default Landing;