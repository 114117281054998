import {useEffect, useState} from 'react';
import ChooseSystem from "./ChooseSystem";
import ShiaiPricing from "./ShiaiPricing";
import ManagerPricing from "./ManagerPricing";

/**
 * Returns true if the country is restricted to use only JudoManager.
 *
 * As agreed with Datastat.
 * @param countryCode
 * @returns {boolean}
 */
function isCountryManagerRestricted(countryCode) {
    return countryCode !== 'PL' && countryCode !== 'SK' && countryCode !== 'CZ';
}

function PricingCard() {
    const getFixedCountryCode = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('c');
    }
    const getFixedSystem = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get('s');
    }


    const [countryCode, setCountryCode] = useState(getFixedCountryCode());
    const [isDefaultCountryCode, setIsDefaultCountryCode] = useState(getFixedSystem() === null);
    const [system, setSystem] = useState(getFixedSystem());

    const managerOnly = isCountryManagerRestricted(countryCode);

    useEffect(() => {
        const url = new URL(window.location);
        if (countryCode) {
            url.searchParams.set('c', countryCode);
        } else {
            url.searchParams.delete('c');
        }
        window.history.pushState({}, '', url);
    }, [countryCode]);

    useEffect(() => {
        const url = new URL(window.location);
        if (system) {
            url.searchParams.set('s', system);
        } else {
            url.searchParams.delete('s');
        }
        window.history.pushState({}, '', url);
    }, [system]);

    useEffect(() => {
        const findCountryCode = async () => {
            const response = await fetch('https://api.country.is');
            const payload = await response.json();
            return payload['country'];
        }

        const loadCountryCode = async () => {
            const userCountryCode = await findCountryCode();
            let selectedCountryCode = getFixedCountryCode();

            setIsDefaultCountryCode(userCountryCode === selectedCountryCode);

            setCountryCode(selectedCountryCode || userCountryCode);
        }

        loadCountryCode();
    }, [countryCode]);

    return (<div
        style={{
            width: 450,
            maxWidth: "90vw",
            paddingTop: 8,
            background: "white",
            boxShadow: "0 0 30px 5px rgba(0, 0, 0, 0.05)",
            borderRadius: 64,
            overflow: "hidden",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            display: "inline-flex",
        }}
    >
        <div style={{
            paddingTop: 12,
            paddingLeft: 32,
            width: "100%",
            fontSize: 14,
            fontFamily: "Roboto",
            fontWeight: "normal",
            color: "#6a6a6a",
            display: system === null || managerOnly ? 'none' : "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
        }}
             onClick={() => {
                 setSystem(null);
             }}
        >
            <span className="material-symbols-outlined"
                  style={{fontVariationSettings: "'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 24"}}>arrow_back</span>

        </div>
        {system === null && !managerOnly && <ChooseSystem setSystem={setSystem}/>}
        {system === 'shiai' && !managerOnly && <ShiaiPricing countryCode={countryCode}
                                                             isDefaultCountryCode={isDefaultCountryCode}/>}
        {(system === 'manager' || managerOnly) && <ManagerPricing/>}
    </div>);
}

export default PricingCard;