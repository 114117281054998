import React from 'react';

const CountrySelect = ({countryCode}) => {
    const handleChange = (event) => {
        const code = event.target.value;
        const url = new URL(window.location);
        if (code) {
            url.searchParams.set('c', code);
        } else {
            url.searchParams.delete('c');
        }
        window.history.pushState({}, '', url);

        // Force reload
        window.location.reload();
    };

    return (
        <select
            className="country-select"
            style={{
                color: "#0258C9",
                cursor: "pointer",
                appearance: "none",
                border: "none",
                background: "none",
                paddingLeft: "4px",
                width: "100px",
                display: "inline-block",
            }}
            onChange={handleChange}
            value={countryCode ?? '-'}
        >
            <option value="CZ">Czech Republic</option>
            <option value="PL">Poland</option>
            <option value="SK">Slovakia</option>
        </select>
    );
};

export default CountrySelect;
